@import "src/styles/vars";

.wrapper {
  background-color: white;

  overflow: hidden;

  .qrcode {
      width: 100px;
      height: 100px;
  }

  .code{
    color: red;
    font-weight: bold;
  }

  a {
    font-weight: bold;
    text-decoration: underline;
  }

  li{
  }
  .printContent {
    margin-left: 55px;
    font-size: 12px;
    line-height: 20px;

    h1 {
      padding-top: 50px;
      img {
        width: 155px;
        src: url('../../../../images/png/logo-black.png');
        filter: Gray();
        filter: url('#grayscale');
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%); /* future-proof */
      }

    }

    a{
      color: #000;
    }
    .title {
      margin-bottom: 0;
      font-size: 16px;
      font-weight: 600;
      &.schoolName {
        margin-bottom: 15px;
      }
    }

    .description {
      margin-bottom: 10px;
      
      a {
        text-decoration: underline;
        color: black;
        font-weight: 600;
      }
    }

    ol {
      margin-top: 0;
      li {
        margin-bottom: 5px;
        ul {
          padding-left: 40px;
          margin-top: 5px;
        }
      }
    }

    .cardWall {
      padding: 15px;
      border: 1px solid $color-divider;
      border-radius: 5px;
      margin-bottom: 15px;
      p {
        margin-bottom: 5px;
        display: flex;
        span:first-child {
          width: 230px;
          margin-right: 30px;
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      img {
        width: 120px;
        margin: 0 10px;
      }
    }
  }
}