@import "src/styles/vars";

.dateColumn {
  padding-left: $unit*4 !important;
}

.commentColumn {
  width: 30%;
}

.beneficiaryColumn {
  padding-right: $unit*3 !important;
}

.dateCell {
  @include PrimarySemibold;
  padding-left: $unit*4 !important;
  font-weight: 600 !important;
  color: $color-text-primary !important;
}

.amountCell {
  @include PrimarySemibold;
  font-weight: 600 !important;
  color: $color-text-primary !important;
}

.bookGiftCell{
  @include PrimaryMedium;
  color: $color-text-primary !important;
}


.tableRow {

  vertical-align: top;

  a {
    display: flex;
    align-items: center;
    color: $color-text-secondary;

    svg {
      padding-left: $unit/2;
      width: 14px;
      height: 14px;
    }

    &:hover {
      color: $color-text-blue;
      font-weight: bolder;
    }
  }

  &:hover {
    background-color: $color-bg-focus-table-row;
  }
}


.nameWrapper {
  display: grid;
  gap: $unit/2;

  .name {
    @include PrimarySemibold;
    color: $color-text-secondary;
  }

  .email {
    @include SmallSemibold;
  }

  .comment {
    @extend .email;
    font-weight: 500;
  }
}

.beneficiaryWrapper {
  @extend .nameWrapper;
  display: flex;
  min-width: 160px;


  .avatarWrapper {
    width: 36px;
    height: 36px;
    border-radius: 100px;
    background-color: $color-bg-gray-light;
    margin-right: $unit;
    img{
      width: 36px;
      height: 36px;
    }
  }


  .description {
    @include SmallRegular;
  }

  .name {
    @include PrimarySemibold;
    color: $color-text-primary;
  }
}

.redirectCell a {
  text-transform: none;
}

.showMoreBtn {
  margin: 30px 0 !important;
  margin-left: 50% !important;
  transform: translate(-50%);
}

:export {
  redirectSvgColor: $_gray-600;
}
