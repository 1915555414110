@import "src/styles/vars";

.headerWrapper {
  display: grid;

  .title {
    @include H1_Bold;
    color: $color-text-black;
  }

  .subTitle {
    @include PrimarySemibold;
    color: $color-text-secondary;
    padding-top: $unit;
  }
}

.bodyWrapper {
  padding-top: $unit * 6;
}

.formsWrapper {
  padding-bottom: $unit * 3;
}

:export {
  fillEditIcon: $_blue-main;
  info: $_gray-600;
}
