@import "src/styles/vars";

//columns
.teamLeadNameColumn {
  @extend .sortCol;
  padding-left: 40px !important;
  min-width: 160px;
}

.registredCountColumn {
  @extend .sortCol;
  min-width: 105px;
  padding-right: 0 !important;
}

.disabledTeamRow{
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.04);
}
//cells
.teamLeadNameCell {
  display: flex;
  align-items: center;
  padding-left: 40px !important;

  .nameWrapper {
    //display: grid;
    //gap: $unit/2;
    padding-left: $unit*2;
    &:hover{
      color: $color-text-blue;
    }

    .name {
      @include PrimarySemibold;
      //color: $color-text-primary !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;


    }

    .teacherName{
      @include SmallSemibold;
      color: $color-text-secondary !important;
      display: flex;
      line-height: 22px;


      align-items: center;
      svg{
        width: 15px;
        height: 16px;
        fill:  $color-text-blue;
        margin-left: $unit/2;
      }
    }
    .email {
      @include SmallRegular;
      color: $color-text-secondary !important;
    }
  }
}

.donationCol {
  @extend .sortCol;
  width: 85px;
  padding-right: 0 !important;
}

.actionCol {
  padding-left: 0 !important;
}

.grayColor {
  color: $color-text-secondary;
}

// sort styles

.sortCol {
  cursor: pointer;
  min-width: 85px;

  .flexWrapper {
    display: flex;
    align-items: center;

    div {
      padding-left: $unit;
    }
  }
}

.actionCell {
  cursor: pointer;
  padding-left: 0 !important;
}

.activeSortCol {
  @include PrimarySemibold;
  font-weight: 600 !important;

  span {
    color: $color-text-primary;
    font-weight: 600 !important;

    &:hover {
      color: $_blue-main;
    }
  }
}

.sortDirection {
  transform: rotate(-180deg);
}

.donationLink {
  a {
    color: $color-text-primary;
    text-decoration: none;
    cursor: pointer;


    &:hover {
      color: $_blue-main ;
    }
  }
}
