@import "src/styles/vars";

.wrapper {
  display: flex;
  align-items: baseline;

  .bodyWrapper {
    width: 100%;
    padding-left: $unit * 3;
    padding-right: $unit * 6;

    .titleWrapper {
      display: grid;
      gap: $unit;

      .headerWrapper {
        display: flex;
        align-items: center;

        .title {
          @include H1_Bold;
          color: $color-text-primary;
        }

        svg {
          padding-left: $unit;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }

    .contentWrapper {
      .section {
        padding-top: $unit * 6;

        .contentSubTitleWrapper {
          display: flex;
          align-items: center;
          width: fit-content;

          .contentSubTitle {
            @include PrimarySemibold;
            color: $color-text-primary;
          }

          svg {
            width: 16px;
            height: 16px;
            padding-left: $unit/2;
          }
        }

        .controlsWrapper {
          display: flex;
          justify-content: space-between;
          padding-top: $unit * 3;
        }

        .resendInvite {
          padding-left: 5px;
          cursor: pointer;
          color: $_blue-main;
          text-decoration: underline;
        }

        .description {
          @include SmallSemibold;
          display: flex;
          padding-top: $unit * 2;
          color: $color-text-secondary;

          svg {
            padding-left: $unit/2;
          }
        }
      }
    }

    .footer {
      padding-top: $unit * 6;

      button + button {
        margin-left: $unit;
      }
    }
  }
}

:export {
  blue: $_blue-main;
  tickStroke: $color-text-secondary;
}
