@import "src/styles/vars";

.wrapper {
  display: grid;
  gap: $unit;

  .rowWrapper {
    display: flex;
    align-items: center;
    margin-left: -9px;

    .helperIcon{
      width: 16px;
    }
  }
}

:export {
  info: $_gray-600;
}
