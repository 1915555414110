@import "src/styles/vars";

.formWrapper {
  height: 100%;
  position: relative;

  .headerWrapper {
    .title {
      @include H1_Bold;
      color: $color-text-real-black;
    }

    .descriptionWrpper {
      @include PrimaryMedium;
      color: $color-text-primary;
      width: 280px;
      padding-top: $unit;
    }
  }

  .bodyWrapper {
    padding-top: $unit*5;

    .bodyDescription {
      @include PrimaryMedium;
      padding-top: 24px;
      color: $color-text-primary;
    }

    .link {
      @include PrimaryMedium;
      padding-top: $unit*3;
      display: flex;
      align-items: center;
      color: $color-text-blue-dark;
      text-decoration: underline;
      cursor: pointer;

      svg {
        padding-left: $unit/2;
        height: 16px;
        width: 16px;
      }
    }
  }

  .footerWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

:export {
  blueRedirectIcon: $_blue-main;
}