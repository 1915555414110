@import "src/styles/vars";

.loadingWrapper {

}


.bg{
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url("../../../../images/logo/ic_logo_blue.svg");
  background-size: 55%;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -30px;
  margin-top: -10px;
}

:export {
  primaryColor: $_blue-light;
  whiteColor: $color-white;
}