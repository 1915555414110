@import "src/styles/vars";

.formWrapper {
  height: 100%;
  position: relative;

  .headerWrapper {
    .title {
      @include H1_Bold;
      color: $color-text-real-black;
    }

    .descriptionWrpper {
      @include PrimaryMedium;
      display: grid;
      color: $color-text-primary;
      padding-top: $unit*2;
    }
  }

  .bodyWrapper {
    padding-top: $unit*3;
    width: 100%;

    .control + .control {
      margin-top: $unit*4;
    }

    .flexWrapper {
      display: flex;
      margin-top: $unit*4;

      .inputZipCode {
        margin-left: $unit;
      }
    }
  }

  .footerWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;

    .back {
      display: flex;
      cursor: pointer;
      align-items: center;
      padding-bottom: 10px;
      left: 0;

      span {
        @include PrimarySemibold;
        padding-left: $unit;
        color: $color-text-blue;
      }
    }

    button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

:export {
  blue: $_blue-main;
}