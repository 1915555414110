@import "src/styles/vars";

.titleWrapper {
  .title {
    @include Title;
    color: $color-text-primary;
  }

  .description {
    @include PrimaryMedium;
    color: $color-text-primary;
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;
      color: $color-text-blue;
      text-decoration: underline;
      cursor: pointer;

      svg {
        padding-left: $unit/2;
      }
    }
  }

  .RadioGroupWrapper {
    display: flex;
    flex-direction: row;
    margin-left: $unit * 3;
  }
}

.titleWrapperTM {
  @extend .titleWrapper;
  display: flex;
  align-items: center;
}

.contentWrapper {
  position: relative;
  padding-top: $unit * 2;
  padding-bottom: $unit * 3;
  margin-left: -$unit * 3;
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  min-height: 500px;

  .avatar {
    margin: $unit * 2;
    cursor: pointer;
  }

  .footerWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

:export {
  redirectSvgColor: $color-text-blue;
}
