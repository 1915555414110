@import "src/styles/vars";

.headerWrapper{
  .headerInfo {
    display: grid;
    color: $_color-black;

    .editTitle{
      @include H1_Bold;
    }
    
    .location{
      font-size: 16px;
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-weight: 400;

      .widthWrapper {
        display: flex;
        width: 100%;
      }

      .locationInfo{
        display: flex;
        padding-top: $unit*1.5;
        justify-content: center;
        a {
          padding-left: $unit/2;
        }
      }

      .datePicker {
        margin-left: $unit*2;
      }

      .btnWrapper{
        display: flex;
        position: absolute;
        right: 25px;

        button + button {
          margin-left: $unit;
        }

        a{
          text-decoration: none;
          margin-left: $unit*2;
        }
      }
    }

    .name{
      @include H1_Bold;
      padding-top: $unit*1.5;
    }

    .descriptionWrapper {
      display: grid;
      gap: $unit*2;

      .description {
        padding-top: $unit;
        
        .dayToEnd {
          color: $color-text-red;
          font-weight: bold;
        }
      }

      .closeBtn {
        margin-bottom: 15px;
      }
    }
  }

  .editedHeaderInfo {
    @extend.headerInfo;
    gap: 20px;
  }
}

.space{
  width: $unit;
}

.divider{
  position: absolute;
  left: 0px;
  width: 900px;
}


.bodyTitle {
  @include Title;
}

.bodyWrapper {
  padding-top: $unit*6;
  padding-bottom: $unit*4;

  .bodyText {
    @include TextRegular;
    padding-top: $unit*2;
    padding-right: 68px;
    white-space: pre-wrap;

    a{
      text-decoration: underline;
    }
  }

  .fundraiserObjectivesWrapper {
    padding-top: $unit*4;
    .objectivesItemsWrapper {
      padding-top: $unit*2;
      padding-left: 15px;
      display: grid;
      gap: $unit;
    }
  }
}

.editedBodyWrapper{
  padding-top: 40px;
  display: flex;

  .mediaGridWrapper {
    display: grid;
    gap: $unit*2;
    padding-left: $unit*2;
  }
}

.fundraiserObjectivesEditWrapper {
  padding-top: $unit*4;
  padding-bottom: $unit*3;

  .objectivesItemsWrapper {
    padding-top: $unit*3;
    display: grid;
    gap: $unit*1.5;
  }
}

.mediaWrapper {
  display: flex;
  gap:  $unit*2;
  margin-top: $unit*3;


}
