@import "src/styles/vars";

.avatarWrapper {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  background-color: $color-bg-gray-light;
  overflow: hidden;
  margin-left: $unit * 4;

  img {
    width: 36px;
  }
}

.nameCol {
  cursor: pointer;
  font-weight: 600 !important;

  &:hover {
    color: $color-text-blue;
  }
}

.actionCol {
  padding-left: 0 !important;
}

.actionCell {
  cursor: pointer;
  padding-left: 0 !important;
}

.sortCol {
  cursor: pointer;
  //min-width: 85px;

  .flexWrapper {
    display: flex;
    align-items: center;

    div {
      padding-left: $unit;
    }
  }
}

.donationCol {
  @extend .sortCol;
  width: 80px;
}

:export {
  blue: $_blue-main;
  purple: $_purple;
  tickStroke: $color-text-secondary;
}


.achNumbers{
  display: flex;
 align-items: center;
  svg {
    padding-left: $unit/2;
    width: 16px;
    height: 16px;
    cursor: pointer;
    fill:  $color-text-secondary
  }
}
.grayColor {
  color: $color-text-secondary !important;
}

.greenColor {
  color: $color-text-green !important;
}

.activeSortCol {
  @include PrimarySemibold;
  font-weight: 600 !important;

  span {
    color: $color-text-primary;
    font-weight: 600 !important;

    &:hover {
      color: $_blue-main;
    }
  }
}

.donationLink {
  text-decoration: none;

  &:hover {
    color: $_blue-main;
  }
}

.sortDirection {
  transform: rotate(-180deg);
}

.showMoreBtn {
  margin: 30px 0 !important;
  margin-left: 50% !important;
  transform: translate(-50%);
}

a {
  color: $color-text-primary;
  cursor: pointer;

  &:hover {
    color: $_blue-main;
  }
}
