@import "src/styles/vars";

.wrapper {
  position: fixed;
  z-index: 10;
  width: 64px;
  height: 100%;
  background-color: #fff;

  .content{
    padding-top: $unit*3;
    justify-content: center;

    .menuBtn {
      margin: $unit;
      margin-bottom: 0;
    }

    @include mobile {
      display: none;
    }

    .logo{
      position: absolute;
      top: 10px;
      left: 90px;

      @include tablet{
        display: none;
      }
    }
  }
}