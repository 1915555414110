@import "src/styles/vars";


.wrapper {
  border-top: 1px solid $color-border-gray-light;
  cursor: pointer;
  user-select: none;

  .closedWrapper {
    display: flex;
    position: relative;
    align-items: center;
    min-height: 63px;

    .title{
      @include PrimarySemibold;
      display: flex;
      align-items: center;
      padding-left: $unit*4;
      //text-transform: uppercase;
      width: 200px;
      min-height: 20px;
      //font-size: 10px;
      color: $color-text-primary;

      svg {
        padding-left: $unit/2;
        width: 16px;
      }
    }

    .value {
      @include PrimaryMedium;
      color: $color-text-primary;
    }

    .editedValue {
      @include PrimarySemibold;
      color: $color-text-primary;
    }

    .chevronButton {
      position: absolute;
      right: $unit*4;
      top: $unit;
    }

    .chevronButtonClose {
      position: absolute;
      right: $unit*4;
      top: $unit;

      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: rotateClose 0.4s 1 forwards;
      animation: rotateClose 0.4s 1 forwards;
    }

    @-webkit-keyframes rotateClose {
      from {
        transform: inherit;
        rotate: inherit;
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      to {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
    }
    
    @keyframes rotateClose {
      from {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
      }
      to {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
    }

    .chevronButtonOpen {
      @extend .chevronButtonClose;

      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: rotateOpen 0.4s 1 forwards;
      animation: rotateOpen 0.4s 1 forwards;
    }

    @-webkit-keyframes rotateOpen {
      from {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      to {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
      }
    }
    
    @keyframes rotateOpen {
      from {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      to {
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
      }
    }
  }

  .openWrapper {
    @extend .closedWrapper;
    align-items: flex-start;
    padding: 21px 0;
    
  }
}
