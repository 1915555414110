@import "src/styles/vars";

.button {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  white-space: nowrap;
}

.root {
  .input {
    display: none;
  }
}

.borderButton {
  border: 1px solid $_blue-main;
}

.loader {
  width: 100%;
}

a {
  text-decoration: none;

  &:hover {
    color: $color-text-primary;
  }
}

:export {
  primaryColorBtn: $_blue-main;
  purpleColorBtn: $_purple;
  accentColorBtn: $_red;
  linkColorBtn: $_gray_500;
  darkBlue: $_blue-dark;

  whiteTextColorBtn: $color-white;
  primaryTextColorBtn: $_blue-main;
  redTextColorBtn: $color-text-red;

  heightButton: $height-button;

  whiteColorBg: $color-white;
  primaryColorBg: $_blue-main;
}
