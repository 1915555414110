@import "src/styles/vars";

.headerWrapper {
  display: flex;
  justify-content: space-between;

  .pageTitleWrapper{
    display: flex;
    align-items: center;

    svg {
      padding-left: $unit;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .title {
      @include H1_Bold;
      font-weight: bold !important;
      color: $color-text-black;
    }

    .subTitle {
      @include PrimarySemibold;
      font-weight: 600;
      color: $color-text-secondary;
    }
  }

  .buttonsWrapper {
    button + button {
      margin-left: $unit;
    }
  }
}

.filtersWrapper{
  display: flex;
  justify-content: space-between;
  padding-top: $unit*5;
  padding-bottom: $unit;
}

.tableWrapper {
  //padding-top: 56px; align with blue header line
  min-height: 300px;
}

:export {
  blueMain: $_blue-main;
  greenLight: $color-bg-green-light;
}