@import "src/styles/vars";

.completedIcon {
  margin-left: -2px;
}

:export {
  defaultStepConnectorColor: $color-border-half-white;
  activeStepConnectorColor: $color-border-white;

  defaultStepCircleColor: $color-border-half-white;
  activeStepCircleColor: $color-border-white;

  defaultLabelColor: $color-border-half-white;
  activeLabelColor:  $color-border-white;

  labelColor: $color-border-white;
}
