@import "src/styles/vars";

.headerWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .titleWrapper {
    display: flex;
    align-items: center;

    .title {
      @include H1_Bold;
      font-weight: bold !important;
      color: $color-text-black;
    }

    svg {
      padding-left: $unit;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  div{
    .downloadButton {
      margin-right: $unit;
    }
  }
}

.summary{
  white-space: pre-wrap;
}

.widgetsWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: $unit * 6;
  padding-right: $unit * 8;

  .raisedFrom {
    font-weight: 600;
  }

  .raisedFromNonePaid {
    font-weight: 600;
    color: $color-text-secondary;
  }
  .raisedFromNonePaidGoal {
    @extend .raisedFromNonePaid;
    font-weight: 400;
  }
}

.receivedDonation {
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    padding-left: $unit/2;
  }
}

.chartWrapper {
  padding-top: $unit * 6;
}

.middleContentWrapper {
  display: flex;
  justify-content: space-between;
}

.progressWrapper {
  display: grid;
  gap: $unit * 2;
}

.stripeStatusWrapper {
  padding-top: $unit * 4;
  margin-left: -$unit * 1.5;

  a {
    text-decoration: none;
  }

  .stripeWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: $unit * 2;

    .flexWrapper {
      display: flex;
      align-items: center;


      .statusWrapper {
        display: flex;
        align-items: center;
        padding-left: $unit * 3;
        .title {
          @include SmallSemibold;
          color: $color-text-secondary;
          padding-right: $unit;
          text-transform: uppercase;
        }
        svg {
          padding-right: $unit/2;
        }
        .value {
          @include PrimarySemibold;
          color: $color-text-blue;
        }
        .email {
          @extend .value;
          max-width: 280px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .accountWrapper {
        @extend .statusWrapper;
        padding-left: $unit * 4;
      }
    }
  }
}

.emptyStripeWrapper {
  padding-top: $unit * 4;

  .stripeWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: $unit * 2;

    .gridWrapper {
      display: grid;
      // width: 600px;

      svg {
        margin-left: -10px;
      }

      .title {
        @include Title;
        padding-top: $unit * 2;
        color: $color-text-real-black;
      }

      .description {
        @include PrimaryMedium;
        color: $color-text-primary;
        padding-top: $unit;
      }
    }

    button {
      margin-top: $unit * 2;
    }
  }
}

.descriptionUnpaidWrapper {
  padding-top: $unit * 6;
  padding-bottom: $unit * 4;
  .title {
    @include Title;
    color: $color-text-real-black;
  }

  .description {
    @include PrimaryMedium;
    padding-top: $unit/2;
    color: $color-text-primary;

    .flexWrapper {
      display: flex;
      .link {
        text-decoration: underline;
        cursor: pointer;

        a {
          display: flex;
          align-items: center;
          color: $color-text-blue;
          svg {
            padding-left: $unit/2;
          }
        }
      }
    }
  }
}

.readingEmptyWrapper {
  padding-top: $unit * 6;

  padding-bottom: $unit * 2;

  .flexWrapper {
    display: flex;
    align-items: center;
  }

  .readingWrapper {
    padding-top: $unit * 4;
    .title {
      @include Title;
      color: $color-text-real-black;
    }

    .description {
      @include PrimaryMedium;
      padding-top: $unit;
      color: $color-text-primary;
    }
  }
}

.readingUnpaidWrapper {
  @extend .readingEmptyWrapper;

  padding-bottom: $unit *3;


  .flexWrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

}

.divider {
  position: absolute;
  left: 0;
  width: 100%;
}

:export {
  blue: $_blue-main;
  purple: $_purple;
  tickStroke: $color-text-secondary;
}
