//
@mixin PrimarySemibold {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

@mixin PrimaryMedium {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
}

@mixin SmallRegular {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  line-height: 15px;
}

@mixin SmallSemibold {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
}

@mixin PrimarySemibold {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
}

@mixin Title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}

@mixin H1_Bold {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 140%;
}

@mixin H1_Medium {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 39px;
}

@mixin TextRegular {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}