@import "src/styles/vars";

.tooltipWrapper {
  display: flex;
}

:export {
  blueBgTooltip: $_blue-main;
  purpleBgTooltip: $_purple;
}
