@import "src/styles/vars";

.wrapper {
  display: flex;
  justify-content: space-between;

  .titleWrapper {
      display: grid;

      .description {
          width: 530px;
      }
  }
}

:export {
  btnBorderColor: $_red;
}
