@import "src/styles/vars";

.avatarWrapper {
  position: relative;
  overflow: hidden;
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background-color: $_gray-200;
  z-index: 1;

  img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 60px;
  }
}

.currentAvatarWrapper {
  @extend .avatarWrapper;
  border: 4px solid $_blue-main;
  box-sizing: border-box;
}