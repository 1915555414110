@import "src/styles/vars";

.wrapper{
  width: 100%;
  height: 0;
  background-color: $color-bg-primary-header;
  transition: .5s ease;

  .content {
    display: flex;
    align-items: flex-start;
    padding-top: 22px;
    padding-left: 97px;
    background-color: $color-bg-primary-header;
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 60px;

    .logo {
      min-width: 175px;
    }

    .contentMenu {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 0 $unit*4;

      .breadCrumb {
        display: flex;
      }
    }

    .profileMenu{
      margin-right: 100px;
      cursor: pointer;
    }
  }
}

.underLayout{
  transition: .8s ease;
  height: $body-header-height;
}

.menuHeader {
  display: grid;
  position: relative;
  // padding-bottom: $unit;

  .name {
    font-weight: 700;
  }

  .email {
    font-size: 14px;
    color: $color-text-secondary;
  }
}
