@import "src/styles/vars";


.mediaWrapper {
  display: flex;
  position: relative;
  height: fit-content;
  width: 136px;
  height: 100px;

  .commnStyles {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .emptyWrapper {
    border-radius: 10px;
    width: 100%;
    padding: $unit;
    background-color: $color-bg-gray-light;
    position: relative;

    .noText {
      @include SmallSemibold;
      color: $color-text-placeholder;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.mediaUploader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: fit-content;
  margin: 0;
  padding: 0;
  transform: translate(-50%, -50%);
  z-index: 100;
}