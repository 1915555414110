@import "src/styles/vars";

.wrapper{
  background-color: $color-bg-dark-blue;
  width: 170px;
  height: 36px;
  border-radius: $border-radius-big;

  .containerWrapper {
    display: flex;
    align-items: center;

    .userName {
      @include PrimaryMedium;
      color: white;
      padding-left: $unit;
      width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .avatarWrapper {
      border-radius: 100px;
      width: 36px;
      height: 36px;
      overflow: hidden;
  
      img{
        background-color: $color-bg-white;
        width: 36px;
        height: 36px;
      }
    }
  }
  
  // button {
  //   padding: 0;
  //   height: 100%;
  //   width: 100%;
  //   border-radius: $border-radius-big;
  //   display: flex;
  //   justify-content: space-between;

  //   svg{
  //     margin-right: $unit*2;
  //   }

  //   .userName{
  //     @include PrimarySemibold;
  //     font-weight: 500;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     color: $color-text-white;
  //     width: 82px;
  //     padding: 0 $unit;
  //     text-transform: none;
  //   }


  //   .itemWrapper {
  //     @extend .userName;
  //     width: 100%;
  //   }

  //   .avatarWrapper {
  //     background-color: $color-bg-white;
  //     border-radius: 100px;
  //     width: 36px;
  //     height: 36px;
  //     overflow: hidden;
  
  //     img{
  //       width: 36px;
  //       height: 36px;
  //     }

  //     svg {
  //       width: 36px;
  //       height: 36px;
  //     }
  //   }
  
  //   .rotateArrow{
  //     transform: rotate(-180deg);
  //   }
  // }
}
