@import "src/styles/vars";

.AppLayout{
  min-width: 960px;
  background-color: $color-bg-gray-light;
  height: 100%;
  
  .screen {
    min-width: 600px;
    min-height: 500px;

    @include mobile {
      min-width: auto;
    }
  }
}

:export {
  primaryColor: $_blue-main;
}
