@import "src/styles/vars";

.avatarWrapper {
  position: relative;
  width: fit-content;
  cursor: pointer;

  .imgWrapper {
    position: relative;
    overflow: hidden;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: $_gray-200;
    z-index: 1;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
    }
  }

  .editIconWrapper {
    position: absolute;
    right: -10px;
    bottom: -10px;
    z-index: 3;
    background-color: $color-white;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    svg {
      position: absolute;
      left: 10px;
      top: 10px;
    }
  }
}

:export {
  fillEditIcon: $_blue-main;
}
