@import "./vars";

html,
body,
#root {
  height: 100%;
  margin: 0;
  min-width: 960px;
  padding: 0;
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  line-height: 22px;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  color: $color-text-primary;
  background-color: $color-bg-gray-light;
}

.screenWrapper {
  display: grid;
  gap: $unit * 5;
  z-index: 1;
  position: absolute;
  width: 950px;
  top: 0;
  left: 50%;
  margin-top: 140px;
  transform: translate(-50%);
  padding-bottom: 30px;
  margin-left: 30px;

  .content {
    position: relative;
    background: #ffffff;
    width: 100%;
    min-height: 150px;
    border-radius: 20px;
    height: fit-content;

    .contentWrapper {
      padding: $unit * 4;
      padding-bottom: $unit * 2;

      .contentTitle {
        @include Title;
        color: $color-text-primary;
        margin-bottom: $unit * 3;
      }
    }

    .contentWithShadowWrapper {
      @extend .contentWrapper;
      border-radius: 20px;
      min-height: 250px;
      box-shadow: 4px 4px 30px rgba(135, 180, 206, 0.24);

    }
  }

  .studentRosterContent {
    @extend .content;

    padding-bottom: 10px;
  }
}

h3 {
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
  //align-items: center;
}

.widget {
  background-color: $color-white;
  border-radius: $unit * 1.5;
  padding: $unit;
  height: fit-content;
}

.text-center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.widget {
  background-color: $color-white;
  border-radius: $unit * 1.5;
  padding: $unit * 2;
  height: fit-content;
}

.storybookContainer {
  width: 375px;
  background-color: #fff;
  border-radius: $unit;
  margin-bottom: $unit;
  padding: $unit * 2;
}

.primaryBg {
  background-color: red;
}

.whiteIcon {
  path {
    fill: #fff;
  }
}

.primaryIcon {
  path {
    fill: red;
  }
}

.storybookGrid {
  display: grid;
  gap: 20px;

  div {
    display: flex;
    justify-content: space-between;
  }
}

// TABLE

.table {
  width: 100%;
  border-collapse: collapse;

  tbody > tr:not(:last-child) {
    border-bottom: 1px solid $color-border-gray-light;
  }

  thead > tr {
    border-bottom: 1px solid $color-border-gray-light;
  }
}

.tableWithBottomBorder {
  @extend .table;

  tbody > tr {
    border-bottom: 1px solid $color-border-gray-light;
  }
}

.table > tbody > tr > td {
  @include TextRegular;
  color: $color-text-primary;

  padding: 22px 10px;
}

.table > thead > tr > th {
  @include SmallSemibold;
  user-select: none;
  font-size: 11px !important;
  color: $color-text-secondary;

  text-transform: uppercase;
  text-align: left;
  padding: 10px 10px;
}

.tableWithHover {
  @extend .table;

  tbody > tr:hover {
    background-color: $color-bg-focus-table-row;
  }
}

.tableStudentsRoster {
  @extend .tableWithHover;

  tbody > tr {
    border-bottom: 1px solid $color-border-gray-light;
  }
}

.noDataTable {
  @include PrimaryMedium;
  color: $color-text-secondary;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 60%;
  transform: translate(-50%);
}

/* Loading Spinner */

.loaderContainer {
  // width: 100px;
  // height: 100px;
  display: block;
  background-image: url(../images/logo-spinner.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 300;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}


.loader {
  font-size: 10px;
  position: absolute;
  left: 50%;
  top:50%;
  margin-top: -50px;
  margin-left: -30px;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(29, 172, 227, 0.2);
  border-right: 1.1em solid rgba(29, 172, 227, 0.2);
  border-bottom: 1.1em solid rgba(29, 172, 227, 0.2);
  border-left: 1.1em solid #1dace3;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 0.6s infinite linear;
  animation: load8 0.6s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

//link propertys

// a {
//   color: $color-text-secondary;
// }

//divider

.divider {
  background-color: $color-border-gray-light !important;
}
