@import "src/styles/vars";

.checkboxWrapper {
  display: flex;
  align-items: center;
}

:export {
  colorMain: $_blue-main;
  colorDisabled: $_gray_500;
}

.label{
  cursor: pointer;
  user-select: none;
}

.disabledLabel {
  @extend .label;
  cursor: default;
}