@import "src/styles/vars";

.widgetWrapper {
  display: flex;
  margin-left: $unit;

  .closedEventButton {
    position: relative;
    display: flex;
    align-items: center;
    background-color: $_blue-deep-dark;
    border-radius: 20px;
    padding-left: $unit;
    margin-right: $unit;

    svg{
      padding-right: $unit;
    }

    .description {
      @include SmallSemibold;
      display: flex;
      white-space: nowrap;
      color: $_light_yellow;
    }

    &:disabled {
      opacity: 1;
    }

    .startNewBtn {
      margin-left: 10px;
    }
  }

  .valueBtn {
    padding-left: $unit;
    padding-right: $unit;
    background-color: $color-bg-dark-blue;

    span {
      @include PrimarySemibold;

      svg {
        width: 17px;
        height: 11px;
      }
    }
  }

  .openEventWrapper {
    display: flex;
    width: 100%;
    overflow: hidden;

    .iconBtn {
      background-color: $color-bg-dark-blue;
    }

    svg {
      width: 17px;
      height: 11px;
    }
  }
}

:export {
  deepGrayBG: $color-bg-deep-gray;
  darkBlue: $color-bg-dark-blue;
  lightYellow: $_light_yellow;
  yellowBG: $color-bg-yellow;
  purpleBG: $color-bg-purple;
  redBG: $color-bg-red;
  whiteBG: $color-white;
  deepDarkBlueBG: $_blue-deep-dark;
}

.startIcon {
  overflow: hidden;
  margin-right: $unit;
  min-width: 17px;
  min-height: 17px;
}

.button {
  @include PrimarySemibold;

  margin-right: 10px;
  text-decoration: none;
  width: 16px;
  cursor: pointer;

  text-align: center;
  background-color: $color-bg-dark-blue;
  border-radius: 50px;
  padding: 12px 16px;
  color: white;
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  &.donate {
    width: 70px;
  }

  &.students {
    width: 45px;
  }

  &.small {
    width: 45px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  .value {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .label {
    display: none;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover {
    color: white;
    transition-duration: 0.3s;

    width: 175px;

    .value {
      display: none;
    }

    .label {
      overflow: hidden;
      display: block;
    }

    &.donate {
      width: 140px;
      background-color: $color-bg-green-light;
    }

    &.students {
      width: 160px;
      background-color: $color-bg-purple;
    }
    &.donationPage {
      background-color: $color-bg-red;
    }
  }
}
