@import "src/styles/vars";

.formWrapper {
  height: 100%;
  position: relative;

  .headerWrapper {
    .title {
      @include H1_Bold;
      color: $color-text-real-black;
    }

    .descriptionWrpper {
      @include PrimaryMedium;
      color: $color-text-primary;
      padding-top: $unit;

    }
  }

  .bodyWrapper {
    padding-top: $unit*7;

    .email {
      @include Title;
      color: $color-text-blue;
      padding-top: $unit*3;
    }
  }

  .footerWrapper {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
