@import "src/styles/vars";

.formWrapper {
  height: 100%;
  position: relative;

  .headerWrapper {
    .title {
      @include H1_Bold;
      color: $color-text-real-black;
    }

    .descriptionWrpper {
      @include PrimaryMedium;
      color: $color-text-primary;
      padding-top: $unit;

      a{
        cursor: pointer;
        color: $color-text-blue;
        text-decoration: underline;
      }
    }
  }

  .bodyWrapper {
    padding-top: $unit*7;

    .loginMethodsWrapper {
      display: flex;
      justify-content: space-between;
    }

    .dividerWrapper {
      margin-top: 42px;
      position: relative;

      .or {
        @include PrimaryMedium;
        color: $color-text-secondary;
        position: absolute;
        width: 40px;
        background-color: white;
        text-align: center;
        top: -12px;
        left: 50%;
        transform: translate(-50%, 0px);
      }
    }

    .signinEmailWrapper {
      display: grid;
      gap: 20px;
      padding-top: 42px;
    }
  }

  .footerWrapper {
    position: absolute;
    width: 100%;
    bottom: 0;

    div {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .forgotPasswordBtn {
        @include PrimarySemibold;
        cursor: pointer;
        color: $color-text-blue;

      }
    }
  }
}

:export {
  facebook_btnColor: $_blue-facebook;
}