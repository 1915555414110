@import "src/styles/vars";

.wrapper {
  display: flex;
  align-items: baseline;
  padding-bottom: 40px;

  .bodyWrapper {
    width: 100%;
    padding-left: $unit * 3;
    padding-right: $unit * 6;

    .headerWrapper {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .loginBtnWrapper {
        white-space: nowrap;
        overflow: hidden;
      }

      .titleWrapper {
        display: grid;
        gap: $unit;
        max-width: 450px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .title {
          @include H1_Bold;
          color: $color-text-primary;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .description {
          @include PrimarySemibold;
          color: $color-text-secondary;
          width: 450px;
        }
      }
    }

    .avatarWidgetWrapper {
      display: flex;
      align-items: center;
      padding-top: $unit * 6;

      .description {
        @include TextRegular;
        padding-left: $unit * 4;
        width: 310px;

        a {
          position: relative;
          width: fit-content;
          align-items: center;
          color: $color-text-blue;
          text-decoration: underline;
          cursor: pointer;

          svg {
            position: absolute;
            bottom: 0;
            padding-left: $unit/2;
          }
        }
      }
    }

    .contentWrapper {
      .section {
        padding-top: $unit * 6;

        .contentSubTitle {
          @include PrimarySemibold;
          color: $color-text-primary;
        }

        .controlsWrapper {
          display: flex;
          justify-content: space-between;
          padding-top: $unit * 3;
        }
      }
    }

    .footer {
      padding-top: $unit * 6;

      button + button {
        margin-left: $unit;
      }
    }
  }
}

:export {
  redirectSvgColor: $color-text-blue;
}
