@import "src/styles/vars";


.title {
  @include Title;
  color: $color-text-primary;
}

.bodyWrapper {
  @include TextRegular;
  color: $color-text-primary;
  width: 440px;
  padding-top: $unit;
}

.datePickers{
  display: flex;
  justify-content: space-between;
}
.footer {
  display: flex;
  padding-top: $unit*4;
  justify-content: flex-end;

  button + button {
    margin-left: $unit;
  }
}