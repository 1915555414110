@import "src/styles/vars";

.title {
  @include Title;
  color: $color-text-primary;
}

.bodyWrapper {
  @include TextRegular;
  color: $color-text-primary;
  width: 450px;
  padding-top: $unit;
}

.footerWrapper {
  display: flex;
  padding-top: $unit*4;
  justify-content: flex-end;

  button + button {
    margin-left: $unit;
  }
}