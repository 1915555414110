@import "src/styles/vars";

.tableWrapper {
  padding-top: 26px;
  min-height: 300px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;

  .pageTitleWrapper{
    display: grid;

    .titleWithTooltip{
      display: flex;
      align-items: center;

      svg {
        padding-left: $unit;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .title {
      @include H1_Bold;
      font-weight: bold !important;
      color: $color-text-black;
    }

    .subTitle {
      @include PrimarySemibold;
      line-height: 25px;
      font-weight: 600;
      color: $color-text-secondary;
    }
  }
}

.headerControls {
  display: flex;
  gap: $unit;
}

.teacherIcon{
  fill: $_blue-main;
}

:export {
  blueMain: $_blue-main;
}
