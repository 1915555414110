@import "src/styles/vars";

.formWrapper {
  height: 100%;
  position: relative;

  .headerWrapper {
    .title {
      @include H1_Bold;
      color: $color-text-real-black;
    }

    .descriptionWrpper {
      @include PrimaryMedium;
      color: $color-text-primary;
      padding-top: $unit;
      display: flex;
      align-items: center;
      max-width: 440px;
      margin-top: $unit*4;

      .avatarWrapper {
        position: relative;
        width: fit-content;
        cursor: pointer;
    
        .imgWrapper {
          position: relative;
          overflow: hidden;
          width: 66px;
          height: 66px;
          border-radius: 50%;
          background-color: $_gray-200;
          z-index: 1;
    
          img{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60px;
            height: 60px;
          }
        }
    
        button {
          position: absolute;
          right: -5px;
          bottom: -10px;
          z-index: 3;
          background-color: $color-white;
        }
      }

      .description {
        padding-left: $unit*3;
        width: 300px;
      }
    }
  }

  .bodyWrapper {
    padding-top: $unit*7;

    .flexWrapper {
      display: flex;
      justify-content: space-between;
      padding-top: $unit*3;
    }

    .checkBoxWrapper {
      display: flex;
      padding-top: $unit*4;
      margin-left: -$unit*1.5;
      align-items: center;

      .textValue{
        @include SmallSemibold;
        width: 360px;
        font-weight: 500;
        color: $color-text-secondary;

        a{
          text-decoration: underline;
        }
      }
    }
  }

  .footerWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;

    .back {
      display: flex;
      cursor: pointer;
      align-items: center;
      padding-bottom: 10px;
      left: 0;

      span {
        @include PrimarySemibold;
        padding-left: $unit;
        color: $color-text-blue;
      }
    }

    button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

:export {
  blue: $_blue-main;
  fillEditIcon: $_blue-main;
}