@import "src/styles/vars";


.wrapper{
  display: grid;
  height: fit-content;

  .title {
    @include PrimarySemibold;
    color: $color-text-primary;
  }

  .description {
    @include SmallSemibold;
    color: $color-text-secondary;
    padding-top: $unit;

  }

  .bodyWrapper {
    display: flex;
    //padding-top: $unit*3;

    div + div {
      margin-left: $unit;
    }
  }

  .footerWrapper {
    display: flex;
    padding-top: $unit*3;
    
    button + button {
      margin-left: $unit;
    }
  }
}
