@import "src/styles/vars";

.wrapper {
  .titleWrapper {
    display: flex;
    align-items: center;

    .title {
      @include H1_Bold;
      font-weight: bold !important;
      color: $color-text-black;
    }

    svg {
      padding-left: $unit;
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }

  .emailContentWrapper {
    padding-top: $unit * 2;

    .contentTitle {
      @include PrimarySemibold;
      color: $color-text-primary;
    }

    .textFieldsWrapper {
      padding-top: $unit * 3;

      .messageField {
        margin-top: $unit * 2;
      }
    }
  }

  .recepientsWrapper {
    padding-top: $unit * 6;
    height: 160px;

    .title {
      @include PrimarySemibold;
      color: $color-text-primary;
    }

    .checkBoxWrapper {
      display: flex;
      margin-left: -10px;
      padding-top: $unit * 2;

      .text {
        @include PrimarySemibold;
        color: $color-text-secondary;
      }

      div + div {
        padding-left: $unit * 4;
      }
    }
  }

  .infoWrapper {
    position: absolute;
    bottom: 100px;
    .description {
      @include TextRegular;
      padding-top: $unit * 3;
      color: $color-text-primary;
    }
  }

  .sendBtn {
    position: absolute;
    bottom: 30px;
  }
}

:export {
  blue: $_blue-main;
}
