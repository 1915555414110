@import "src/styles/vars";

.customTooltip {
  padding: $unit;
  background-color: $_gray-100;

  .label {
    @include SmallSemibold;
  }
}

:export {
  blue: $_blue-main;
  purple: $_purple;
  gray: $_gray-200;
  tickStroke: $color-text-secondary;
}
