@import "src/styles/vars";

.wrapper {
  display: grid;
  min-height: 56px;

  .titleWrapper {
    display: flex;
    align-items: center;
    
    .icon {
      padding-right: $unit;
    }

    .subTitle{
      @include PrimarySemibold;
      margin-top: $unit;
      font-weight: 500;
    }

    .title{
      @include H1_Medium;
    }

    .blueTitle {
      @extend.title;
      color: $color-text-blue;
    }

    .blackTitle {
      @extend.title;
      color: $color-text-primary;
    }

    .secondaryTitle {
      @extend.title;
      color: $color-text-secondary;
    }

    .purpleTitle {
      @extend.title;
      color: $_purple;
    }
  }

  .descriptionWrapper{
    @include PrimarySemibold;
    color: $color-text-secondary;
  }

  .descriptionHoverWrapper {
    @extend.descriptionWrapper;

    &:hover {
      cursor: pointer;
    }
  }
}