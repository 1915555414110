@import "src/styles/vars";

.modalWrapper {
  width: 600px;
  padding: $unit*3;
  background-color: $color-bg-white;
  border-radius: $border-radius-small;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  gap: $unit*4;

  .modalTitle{
    @include Title;
    color: $color-text-black;
  }

  .modalDesctiption {
    @include TextRegular;
    width: 520px;
    color: $color-text-secondary;
    display: grid;
    gap: $unit;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
