@import "src/styles/vars";

.profileMenu {
  display: grid;
  position: relative;
  // padding-bottom: $unit;

  .name {
    font-weight: 700;
  }

  .email {
    font-size: 14px;
    color: $color-text-secondary;
  }
}