$unit: 8px;
$border-radius-small: 4px;
$border-radius-big: 30px;

$sidebar-width: 278px;
$body-header-height: 320px;
$mobile-photo-head: 180px;
$desktop-screen-content-width: 350px;
$widget-radius: $unit*1.5;

//size height controlls small medium big (selects, inputs, etc.)
//$height-small: 32px;
$height-button: 40px;

@mixin mobile {
  @media screen and (max-width: 600px) {
    @content;
  }
}

@mixin tablet {
  @media screen and (max-width: 1130px) {
    @content;
  }
}
