@import "src/styles/vars";

.titleWrapper {
  display: flex;
  padding-top: $unit*4;
  flex-direction: column;
  width: 100%;
  align-items: center;

  .title {
    @include H1_Bold;
    font-weight: 700;
    color: $color-text-real-black;
    padding-top: $unit;
  }

  .description {
    @include TextRegular;
    color: $color-text-primary;
    width: 300px;
    text-align: center;
    padding-top: $unit;
  }
}

.contentWrapper {
  display: grid;
  padding-top: $unit*6;
  gap: $unit*2;

  .link {
    position: relative;
    user-select: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    border-radius: 4px;
    background-color: $color-bg-gray-light;

    .urlText{
      @include TextRegular;
      color: $color-text-primary;
    }

    .copyIcon {
      position: absolute;
      cursor: pointer;
      right: $unit*2;
    }
  }

  .inviteCodeWrapper {
    @include PrimaryMedium;
    text-align: center;
    color: $color-text-secondary;

    span{
      font-weight: 600;
      color: $color-text-blue;
    }
  }

  .letterLink {
    color:white;
  }
}