@import "src/styles/vars";

.wrapper {
  .header {
    display: flex;
    justify-content: space-between;

    .titleWrapper {
      display: grid;

      .title {
        @include H1_Bold;
        font-weight: 700;
        color: $color-text-black;
      }

      .titleDescription {
        @include PrimarySemibold;
        color: $color-text-secondary;
        line-height: 25px;
      }

      .titleWithTooltip {
        display: flex;
        align-items: center;

        svg {
          padding-left: $unit;
          width: 24px;
          height: 24px;
          cursor: pointer;
        }
      }
    }

    .headerControls {
      button + button {
        margin-left: $unit;
      }
    }
  }

  .filtersWrapper {
    display: flex;
    justify-content: space-between;
    padding-top: $unit * 5;
    padding-bottom: $unit;
  }
}

.tableWrapper {
  min-height: 300px;
}

:export {
  downLoadIconColor: $_blue-main;
}

:export {
  blueMain: $_blue-main;
}
