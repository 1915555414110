@import "src/styles/vars";

.tableWrapper {
  padding-top: 26px;
  min-height: 300px;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;

  .pageTitleWrapper{
    display: grid;

    .titleWithTooltip{
      display: flex;
      align-items: center;

      svg {
        padding-left: $unit;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }

    .title {
      @include H1_Bold;
      font-weight: bold !important;
      color: $color-text-black;
    }

    .subTitle {
      @include PrimarySemibold;
      margin-top: $unit;
      line-height: 25px;
      font-weight: 600;
      color: $color-text-secondary;


    }
  }


  .buttons{
    display: flex;
    flex-direction: column;
    gap: $unit;
    color: $color-text-secondary;
  }
  .expiresAt{
    color:$color-text-red;
  }
}

:export {
  blueMain: $_blue-main;
}
