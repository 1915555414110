@import "src/styles/vars";

.formWrapper {
  height: 100%;
  position: relative;

  .headerWrapper {
    .title {
      @include H1_Bold;
      color: $color-text-real-black;
    }

    .descriptionWrpper {
      @include PrimaryMedium;
      color: $color-text-primary;
      padding-top: $unit;
      max-width: 440px;
    }
  }

  .bodyWrapper {
    padding-top: $unit*5;

    .goals {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }

    .bodyDescription {
      @include PrimaryMedium;
      padding-top: $unit*3;
      color: $color-text-secondary;
    }
  }

  .footerWrapper {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    width: 100%;

    .back {
      display: flex;
      cursor: pointer;
      align-items: center;
      padding-bottom: 10px;
      left: 0;

      span {
        @include PrimarySemibold;
        padding-left: $unit;
        color: $color-text-blue;
      }
    }

    button {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

:export {
  blue: $_blue-main;
}