@import "src/styles/vars";

.formWrapper {
  position: relative;
  height: 100%;

  .wrapper {
    position: absolute;
    height: 100%;
    left: 50%;
    transform: translate(-50%, 0px);
    text-align: center;

    .bodyWrapper {
      padding-top: $unit*3;
      
      .title{
        @include H1_Bold;
        color: $color-text-real-black;
        padding-top: 26px
      }

      .description{
        @include PrimaryMedium;
        color: $color-text-primary;
        padding-top: $unit;
        width: 340px;
        padding-top: 19px;
      }

      .link {
        @include PrimaryMedium;
        display: flex;
        justify-content: center;
        cursor: pointer;
        text-decoration: underline;
        align-items: center;
        color: $color-text-blue;
        padding-top: 10px;
        
        span {
          padding-left: $unit;
        }
      }
    }

    .footerWrapper {
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 0px);

      img {
        width: 137px;
        margin-bottom: -5px;
      }
    }
  }
}

:export {
  blueIcon: $_blue-main;
}