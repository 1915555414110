@import "src/styles/vars";

.layoutWrapper {
  position: relative;
  height: 100%;
  width: 100%;
  background-image: url("../../../images/signIn/signInLayout.svg");
  background-repeat: no-repeat;
  background-size: cover;

  .headerWrapper {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 60px;
    padding-left: 60px;
    padding-right: 40px;

    a {
      position: absolute;
      .logo {
        width: 224px;
        height: 39px;
      }
    }

    .rightHeaderContainer {
      position: absolute;
      right: 40px;
    }
  }

  .leftContainer {
    position: absolute;
    width: 590px;
    left: 60px;
    top: 50%;
    transform: translate(0, -50%);

    .timelintDescription {
      padding-bottom: 44px;
      width: 300px;
      .title {
        @include Title;
        color: $color-text-white;
        padding-bottom: $unit;
      }

      .description {
        @include PrimaryMedium;
        color: $color-text-white;
      }
    }
  }

  .formWidthWrapper {
    position: relative;
    min-width: 1300px;
    height: 100%;
    margin-top: -80px;
    z-index: 1;

    .formWrapper {
      position: absolute;
      width: 580px;
      height: 590px;
      border-radius: 10px;
      background-color: white;
      padding: 60px;
      box-sizing: border-box;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .footer {
    @include SmallSemibold;
    color: white;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0px);
    z-index: 2;
    a{
      color: white;
      cursor: pointer;
    }
  }
}
