//Color palette (from figma)
$_blue-main: #00AEEF;
$_blue-light: #3ac6ff;
$_blue-dark: #009BD5;
$_blue-deep-dark: #0583B2;
$_blue-facebook: #3D5B98;
$_red: #F0503C;
$_green: #1E960A;
$_green_light: #00D97E;
$_light_yellow:#FFCE85;
$_yellow: #FFBC57;
$_purple: #B14FDD;
$_gray-900: #3F4851;
$_gray-800: #717171;
$_gray-600: #8089A6;
$_gray_500: #A9B0BB;
$_gray_300: #E5E5E5;
$_gray-200: #ECF5FA;
$_gray-100: #f5f9fa;
$color-white: #FFFFFF;
$color-white-dark: #f9f9f9;
$_color-black:#202C54;
$_color-real-black: #000000;

// Avatar background colors
$_avatar_blue: #00AEEF;
$_avatar_red: #F0503C;
$_avatar_purple: #B14FDD;
$_avatar_dark_blue: #009BD5;
$_avatar_green: #1E960A;
$_avatar_default: $_gray_500;

//Text colors
$color-text-primary: $_gray-900;
$color-text-secondary: $_gray-600;
$color-text-placeholder: $_gray-500;
$color-text-white: $color-white;
$color-text-black: $_color-black;
$color-text-real-black: $_color-real-black;
$color-text-blue: $_blue-main;
$color-text-blue-dark: $_blue-dark;
$color-text-red: $_red;
$color-text-green: $_green;


//Background colors
$color-bg-primary-header: $_blue-main;
$color-bg-dark-blue: $_blue-dark;
$color-bg-dark-gray: $_gray-900;
$color-bg-deep-gray: $_gray-800;
$color-bg-gray-light: $_gray-200;
$color-bg-yellow: $_yellow;
$color-bg-purple: $_purple;
$color-bg-red: $_red;
$color-bg-white: $color-white;
$color-bg-focus-table-row: $color-white-dark;
$color-bg-green-light: $_green_light;

//border color
$color-border-white: $color-white;
$color-border-half-white: rgba($color-white, 0.5);
$color-border-gray: $_gray_500;
$color-border-gray-light: $_gray_300;
$color-divider: rgba(0, 0, 0, 0.1);